<template>
  <el-container class="index-box">
    <el-aside width="asideWidth">
     <div class="logo">
        <img v-show="!isCollapse" src="./../assets/APP_logo.png" alt="ELementUI" width="50" height="50">
      </div>
      <sidebar :isCollapse="isCollapse"></sidebar>
   </el-aside>
    <el-container>
      <el-header>
        <headers/>
      </el-header>
      <el-main>
        <router-view v-if="isRouterAlive"/>
      </el-main>
     </el-container>
  </el-container>
</template>
<script>
import sidebar from '@/components/Sidebar/sidebar'
import headers from '@/components/Headers/header'
export default {
  components: {
    sidebar,
    headers
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  computed: {
    isCollapse () {
      return this.$store.state.isCollapse
    },
    asideWidth () {
      return this.$store.state.isCollapse ? 'auto' : '200px'
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.index-box {
    overflow: hidden;

  height: 100%;
  min-height: 100%;
  .el-container{
    height: 100%;
    .el-main {
      height: 100%;
    }
  }
  .el-aside {
    background-color: #20222A;
    // height: 100vh;
    height: 100%;
    .logo {
      background-color: #20222A;
      // color: rgba(255,255,255,.8);
      // text-align: center;
      // height: 60px;
      // padding-top: 16px;
      // font-size: 16px;
      // box-sizing: border-box;
      border-bottom: 1px solid gray;
    }
  }

}

</style>
